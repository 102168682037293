import React from "react";
import MenuItem from "./MenuItem";
import logo from "../Logo (1).png";
import { FaPlus, FaCheckCircle, FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";


function AA({ isActive }) {
  const titleObj = [
    {
      title: "Create New Campaign",
      icon: FaPlus,
      navigate: "campaigncreate",
    },
    {
      title: "Tabel For Influencer",
      icon: FaUsers,
      navigate: "table",
    },
    {
      title:"User Roles",
      icon:FaUsers,
      navigate:""

    },
    {
      title: "All Campaigns",
      icon: FaCheckCircle,
      navigate: "cam",
    }
  ];

  return (
    <>
      <ul className="menu1 d-flex flex-row-reverse">
        <div
          className={` ${
            isActive ? "has-text-left ml-5" : "my-5 has-text-centered"
          }`}
        >
          <figure className={`image ${isActive ? "is-64x64" : "is-32x32"}`}>
            <img src={logo} alt="Logo" />
          </figure>
          <div
            className={` ${
              isActive ? "has-text-white" : "my-5 has-text-white"
            }`}
          >
            {titleObj.map((obj, index) => (
              <Link to={obj.navigate} key={index}>
              <MenuItem
                title={isActive ? obj.title : ""}
                titleclass="is-size-5"
                icon={obj.icon}
                navigate={MenuItem.navigate}
              />
              </Link>
            ))}
          </div>
        </div>
      </ul>
    </>
  );
}

export default AA;
