import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Config } from '../.env.js';
import 'react-toastify/dist/ReactToastify.css';
// import logo from '../logo192.png';

const MobileValidation = () => {
  const { Email } = useParams(); // Extract email from URL
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const [timer, setTimer] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [expirationTime, setExpirationTime] = useState(null);
  const [loading, setLoading] = useState(false);
  console.log(Email);

  const handleVerifyOtp = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${Config.API_URL}/verifyOtp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          numOtp: otp,
        }),
      });

      const data = await response.json();

      if (data.success) {
        toast.success('OTP verified successfully');
        navigate('/campaigncreate', { replace: true });
      } else {
        toast.error(`Failed to verify OTP: ${data.message}`);
      }
    } catch (error) {
      toast.error(`Error during OTP verification: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleResendOtp = async () => {
    try {
      // Check if there's data stored in localStorage for OTP attempts
      const otpAttemptsData = JSON.parse(localStorage.getItem('otpAttempts')) || {};
      // Get today's date
      const today = new Date().toISOString().split('T')[0];
      // If attempts data for today doesn't exist, initialize it
      if (!otpAttemptsData[today]) {
        otpAttemptsData[today] = 0;
      }
      // Check if attempts for today have exceeded the limit
      if (otpAttemptsData[today] >= 4) {
        toast.error('You have exceeded limit for OTP attempts or resends. Please try after few hours or contact us at contact@hobo.video.');
        return;
      }
      const response = await fetch(`${Config.API_URL}/resendOTP`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: Email,
        }),
      });
      const data = await response.json();
      if (data.success) {
        toast.success('OTP resent successfully');
        // Increment the attempts for today
        otpAttemptsData[today]++;
        // Store updated attempts data in localStorage
        localStorage.setItem('otpAttempts', JSON.stringify(otpAttemptsData));

        let newTimer;
        if (attempts === 0) {
          newTimer = 30;
        } else if (attempts === 1) {
          newTimer = 120;
        } else if (attempts === 2) {
          newTimer = 600;
        } else if (attempts === 3) {
          newTimer = 1800;
        } else {
          toast.error('Your Otp has been expaired . please try again!');
          return;
        }
        setTimer(newTimer);
        setExpirationTime(Date.now() + newTimer * 1000); // Set expiration time
      } else {
        toast.error('Error: ', data.message);
      }
    } catch (error) {
      toast.error('Error during OTP resend:', error.message);
    }
  };
  return (
    <div className='imgfull'>
      <div className='container1 mt-5 pt-5'>
        <div className='logo-card'>
          <figure className="image is-64x64">
            {/* <img src={logo} alt="Logo" /> */}
          </figure>
        </div>
        <div className='container'>
          <h1 className='title mt-5 has-text-white has-text-centered '>OTP Verification</h1>
          <div className='control  pt-4'>
            <div className='field'>
              <label className='label has-text-white mr-5 '>6 Digit OTP Received on Your Registered Email</label>
              <div className='control has-icons-left my-input'>
                <input
                  className='input is-rounded'
                  type='text'
                  placeholder='Enter OTP'
                  value={otp}
                  onChange={(e) => {
                    if (e.target.value.length <= 6) {
                      setOtp(e.target.value);
                    }
                  }}
                  maxLength={6}
                />
                <span className='icon is-small is-left'>
                  <i className='fas fa-key'></i>
                </span>
              </div>
            </div>
            <div className='field is-grouped ' style={{ marginLeft: '370px' }}>
              <div className='control'>
                <button className='button is-primary is-rounded' onClick={handleResendOtp} disabled={timer > 0 || loading}>
                  {loading ? 'Loading...' : timer > 0 ? `Resend OTP in ${timer}s` : 'Resend OTP'}
                </button>
              </div>
              <div className='control'>
                <button className='button is-success is-rounded' onClick={handleVerifyOtp}>
                  {loading ? 'Loading...' : 'Verify OTP'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileValidation;
