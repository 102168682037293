import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Config } from '../.env.js';

const Form = () => {
  const location = useLocation();
  const { campaignName = '', managers = [] } = location.state || {};

  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [fullName, setFullName] = useState('');
  const [AuthContact, setAuthContact] = useState('');
  const [SrName, setSrName] = useState('');
  const [SrContact, setSrContact] = useState('');
  const [whatsappGroup, setWhatsappGroup] = useState('');
  const [city, setCity] = useState('');
  const [products, setProducts] = useState([{ quantity: '', ssUpload: '', productAmount: '' }]);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    if (campaignName && managers) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${Config.API_URL}/api/formdata`, {
            params: { campaignName },
          });
          setData(response.data);
          console.log('Form Data:', response.data);
        } catch (error) {
          console.error('Error fetching form data:', error);
        }
      };
      fetchData();
    }
  }, [campaignName, managers]);
  
  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const response = await axios.get(`${Config.API_URL}/api/product`);
        setData1(response.data);
        console.log('Product Data:', response.data);
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };
    fetchData2();
  }, []);

  const handleProductChange = (index, event) => {
    const updatedProducts = [...products];
    if (event.target.name === "ssUpload") {
      updatedProducts[index][event.target.name] = event.target.files[0];
    } else {
      updatedProducts[index][event.target.name] = event.target.value;
    }
    setProducts(updatedProducts);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data submitted');
    // Implement your form submission logic here
  };

  const filteredProducts = data1.filter((item) => item.campaignName === campaignName);

  return (
    <div className='container'>
      <div className='container1 mt-5'>
        <figure className="image is-64x64">
          {/* <img src={logo} alt="Logo" /> */}
        </figure>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="field is-small">
          <h2>Order Form for {campaignName}</h2>
          <label className="label has-text-left">Your Full Name</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
        </div>

        {data.map((item, index) => (
          <div key={index}>
            {item.customMarketPlaceName && (
              <div className='field is-small'>
                <label className="label has-text-left">{item.customMarketPlaceName} Account Name</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder={`${item.customMarketPlaceName} Account Name`}
                    value={AuthContact}
                    onChange={(e) => setAuthContact(e.target.value)}
                  />
                </div>
              </div>
            )}

            {item.review === 'true' && (
              <div className='field is-small'>
                <label className="label has-text-left">How Many Reviews {item.review} you do per month</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="tel"
                    placeholder={`${item.review} review`}
                    value={SrContact}
                    onChange={(e) => setSrContact(e.target.value)}
                  />
                </div>
              </div>
            )}

            {item.customForoum && (
              <div className='field is-small'>
                <label className="label has-text-left">Your {item.customForoum} Profile Link</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder={`${item.customForoum} Profile Link`}
                    value={SrName}
                    onChange={(e) => setSrName(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
        ))}

        <div className='my-2 has-text-left'>
          <label>Campaign Manager Name*</label>
        </div>
        <div className='field'>
          <div className="control">
            <div className='select is-rounded'>
              <select
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="">Select Campaign Manager</option>
                {managers.map((manager, index) => (
                  <option key={index} value={manager}>{manager}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label">Details Of Brand</label>
          {filteredProducts.map((item, index) => (
            <div className="field is-horizontal" key={index}>
              <div className="field is-small">
                <label className="checkbox">
                  <input
                    type="checkbox"
                  />
                  <label className="label has-text-left mr-6">{item.productName}</label>
                </label>
              </div>
              <div className="field">
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="Quantity"
                    name="quantity"
                    value={products[index]?.quantity || ''}
                    onChange={(e) => handleProductChange(index, e)}
                  />
                  <label className="label">Quantity</label>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="file"
                    name="ssUpload"
                    onChange={(e) => handleProductChange(index, e)}
                  />
                  <label className="label">Upload Screenshot</label>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="Product Amount"
                    name="productAmount"
                    value={products[index]?.productAmount || ''}
                    onChange={(e) => handleProductChange(index, e)}
                  />
                  <label className="label">Product Amount</label>
                </div>
              </div>
            </div>
          ))}
        </div>

        {data.some(item => item.whatsappGroup === 'true') && (
          <div className="field is-small">
            <label className="label">Have You Joined Whatsapp Group</label>
            <label className="radio">
              <input
                type="radio"
                name="whatsappGroup"
                value="yes"
                checked={whatsappGroup === 'yes'}
                onChange={(e) => setWhatsappGroup(e.target.value)}
              />
              Yes
            </label>
            <label className="radio">
              <input
                type="radio"
                name="whatsappGroup"
                value="no"
                checked={whatsappGroup === 'no'}
                onChange={(e) => setWhatsappGroup(e.target.value)}
              />
              No
            </label>
          </div>
        )}

        {data.some(item => item.city === 'true') && (
          <div className="field is-small">
            <label className="checkbox">
              <input
                type="checkbox"
                checked={city}
                onChange={(e) => setCity(e.target.checked)}
              />
              Agree To Terms and Conditions
            </label>
          </div>
        )}

        <div className="field">
          <div className="control">
            <button className="button is-primary is-fullwidth mt-5" type="submit">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
