import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Config } from '../.env.js';

const DataDisplay = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${Config.API_URL}/api/formdata`);
                setData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <p>Loading...</p>;

    return (
        <div className="container">
            <h1 className="title">Form Data</h1>
            <table className="table is-fullwidth">
                <thead>
                    <tr>
                        <th>Full Name</th>
                        <th>Instagram</th>
                        <th>Facebook</th>
                        <th>Twitter</th>
                        <th>YouTube</th>
                        <th>Gender</th>
                        <th>WhatsApp</th>
                        <th>Phone</th>
                        <th>City</th>
                        <th>Custom Social</th>
                        <th>Marketplace Name</th>
                        <th>Forum</th>
                        <th>Reviews</th>
                        <th>Email</th>
                        <th>Order ID</th>
                        <th>Order Date</th>
                        <th>Student</th>
                        <th>College</th>
                        <th>Manager</th>
                        <th>WhatsApp Group</th>
                        <th>Pin Code</th>
                        <th>Product Screenshot</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td>{item.FullName}</td>
                            <td>{item.AuthInstagram ? 'Yes' : 'No'}</td>
                            <td>{item.AuthFacebook ? 'Yes' : 'No'}</td>
                            <td>{item.AuthTwitter ? 'Yes' : 'No'}</td>
                            <td>{item.AuthYouTube ? 'Yes' : 'No'}</td>
                            <td>{item.gender ? 'Yes' : 'No'}</td>
                            <td>{item.whatsapp}</td>
                            <td>{item.Phone}</td>
                            <td>{item.city ? 'Yes' : 'No'}</td>
                            <td>{item.AuthCustomSocial}</td>
                            <td>{item.customMarketPlaceName}</td>
                            <td>{item.customForoum}</td>
                            <td>{item.review}</td>
                            <td>{item.email}</td>
                            <td>{item.order}</td>
                            <td>{item.date}</td>
                            <td>{item.student ? 'Yes' : 'No'}</td>
                            <td>{item.college}</td>
                            <td>{item.manager ? 'Yes' : 'No'}</td>
                            <td>{item.whatsappGroup ? 'Yes' : 'No'}</td>
                            <td>{item.pinCode}</td>
                            <td>{item.productSS ? 'Yes' : 'No'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DataDisplay;
