import React, { useState, useEffect } from "react";
import axios from "axios";
import { Config } from '../.env.js';

const Table = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Config.API_URL}/api/formdata`);
        console.log(response.data)
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const heading = [
    "Instagram",
    "Facebook",
    "Twitter",
    "YouTube",
    "Custom Social",
    "Gender",
    "Whatsapp",
    "Phone",
    "Custom MarketPlace",
    "Custom Review",
    "Custom Social",
    "Custom Profile Link",
    "Email",
    "Order",
    "Date",
    "Student",
    "College",
    "Manager",
    "Whatsapp Group",
    "Pin COde",
    "City",
    "Product Question",
  ];

  return (
    <div
      className="full-page-container"
      style={{
        display: "flex",
        height: "100vh",
        marginLeft: "260px",
        marginTop: "-30px",
      }}
    >
      <div
        className="is-child"
        style={{
          height: "150vh",
          width: "78vw",
          alignItems: "center",
        }}
      >
        <div style={{ overflowY: "auto", flexGrow: 1 }}>
          <div className="container mt-5">
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  {heading.map((item,index) => (
                    <th className="customStyle" key={index}>{item}</th>
                  ))}

                  
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td className="customStyle">
                      {item.FullName ? "Yes" : "No"}
                    </td>
                    <td className="customStyle">
                      {item.AuthInstagram ? "Yes" : "No"}
                    </td>
                    <td className="customStyle">
                      {item.AuthFacebook ? "Yes" : "No"}
                    </td>
                    <td className="customStyle">
                      {item.AuthTwitter ? "Yes" : "No"}
                    </td>
                    <td className="customStyle">
                      {item.AuthYouTube ? "Yes" : "No"}
                    </td>
                    <td className="customStyle">
                      {item.AuthCustomSocial ? "Yes" : "No"}
                    </td>
                    <td className="customStyle">
                      {item.gender ? "Yes" : "No"}
                    </td>
                    <td className="customStyle">
                      {item.whatsapp ? "Yes" : "No"}
                    </td>
                    <td className="customStyle">{item.Phone ? "Yes" : "No"}</td>
                    <td className="customStyle">
                      {item.customMarketPlaceName}
                    </td>
                    <td className="customStyle">{item.review}</td>
                    {/* <td className="customStyle">{item.AuthCustomSocial}</td> */}
                    <td className="customStyle">{item.customForoum}</td>
                    <td className="customStyle">{item.email ? "Yes" : "No"}</td>
                    <td className="customStyle">{item.order ? "Yes" : "No"}</td>
                    <td className="customStyle">{item.date ? "Yes" : "No"}</td>
                    <td className="customStyle">
                      {item.student ? "Yes" : "No"}
                    </td>
                    <td className="customStyle">
                      {item.college ? "Yes" : "No"}
                    </td>
                    <td className="customStyle">
                      {item.manger ? "Yes" : "No"}
                    </td>
                    <td className="customStyle">
                      {item.whatsappGroup ? "Yes" : "No"}
                    </td>
                    <td className="customStyle">
                      {item.pinCode ? "Yes" : "No"}
                    </td>
                    <td className="customStyle">{item.city ? "Yes" : "No"}</td>
                    <td className="customStyle">
                      {item.productSS ? "Yes" : "No"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
