/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import logo from '../Logo (1).png';
import Table from './masterTable';

library.add(fas);
const Lead = () => {

    // menu visiblity set
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [menuVisible, setMenuVisible] = useState(false);

    const handleToggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    //create new campaign 
    const [PhoneNumber, setPhoneNumber] = useState("");
    const [WhatsappNumber, setWhatsappNumber] = useState("");
    const [UserName, setUserName] = useState("")
    //mobile number validation regx


    //const [MobileNumber, setmobilenumber] = useState("");
    const [Email, setemail] = useState();
    const [MobileNumber, setmobilenumber] = useState("");
    //mobile number validation regx
    let hasValNumber = /^[0-9]{1,10}$/.test(PhoneNumber || MobileNumber);

    //email validatin regx
    let hasValEmail = /^[a.-zA.-Z0.-9]+@[a.-zA.-Z0.-9]+\.[A-Za-z]+$/.test(Email);


    // create new campaignForm
    const [showCreateCampaignForm, setShowCreateCampaignForm] = useState(false);
    // const [selectedCampaign, setSelectedCampaign] = useState(null);
    const handleCreateNewCampaignClick = () => {
        setShowCreateCampaignForm(true);
        setShowUserRoles(false);
        setShowInfluencerApplications(false);
        setshowShortlistedInfluencers(false);
    };

    // Influencer Applications 
    const [showInfluencerApplications, setShowInfluencerApplications] = useState(false);
    const handleInfluencerApplicationsClick = () => {
        setShowInfluencerApplications(true);
        setShowCreateCampaignForm(false);
        setshowShortlistedInfluencers(false);
        setShowUserRoles(false);
    }
    const [showBrandApplications, setShowBrandApplications] = useState(false);
    const handleBrandApplicationsClick = () => {
        setShowBrandApplications(true);
        setShowCreateCampaignForm(false);
        setshowShortlistedInfluencers(false);
        setShowUserRoles(false);
    }


    // Shortlisted Influencers
    const [showShortlistedInfluencers, setshowShortlistedInfluencers] = useState(false);
    const handleShortlistedInfluencersClick = () => {
        setshowShortlistedInfluencers(true);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
        setShowUserRoles(false);
    }
    // table
    // Sample table data
    const tableData = [
        ["Data 1", "Data 2", "data3"],
    ];
    // Function to generate CSV data from the tableData
    const generateCSVData = () => {
        const headers = Array.from(document.querySelectorAll('.table th')).map(th => th.innerText);// Add more headers if needed
        const csvData = [headers, ...tableData];
        return csvData;
    };
    // status set 
    const statusOptions = ["Selected", "Rejected", "On Hold"];
    // add shipment
    // const [selectedRows, setSelectedRows] = useState([]);
    const [shipmentStatus, setShipmentStatus] = useState({});

    const handleCheckboxChange = (index, status) => {
        setShipmentStatus((prevShipmentStatus) => ({
            ...prevShipmentStatus,
            [index]: status,
        }))
    };

    // Add Tracking ID
    const [trackingIds, setTrackingIds] = useState({});
    const handleTrackingIdChange = (index, trackingId) => {
        setTrackingIds((prevTrackingIds) => ({
            ...prevTrackingIds,
            [index]: trackingId,
        }));
    };

    // Handler for status change
    const handleStatusChange = (index, selectedStatus) => {
        // Add logic to handle the status change (e.g., update the data or make an API call)
        console.log(`Status changed for row ${index + 1}: ${selectedStatus}`);
    };

    // Option to approve deliverables
    const handleApproval = (index) => {
        // Add logic to handle the approval ( update the data or make an API call)
        console.log(`Deliverables approved for row ${index + 1}`);
    };
    // filters

    const [filterStatus, setFilterStatus] = useState('all');
    const data = generateCSVData();
    const filteredData = data.filter(item => {
        if (filterStatus === 'all') return true;
        return item.status === filterStatus; // Replace 'status' with the actual property that indicates the status
    });

    const [showUserRoles, setShowUserRoles] = useState(false);


    // Handle User Roles link click
    const handleUserRolesClick = () => {
        setShowUserRoles(true);
        setShowAllCampaign(false);
        setShowCreateCampaignForm(false);
        setShowBrandApplications(false);

    };
    //invite form  
    const [showInviteForm, setShowInviteForm] = useState(false);
    const handleInviteTeamMembersClick = () => {
        setShowInviteForm(true);
        setShowUserRoles(false);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
        setshowShortlistedInfluencers(false);
    };
    const [showAllCampaign, setShowAllCampaign] = useState(false);
    const handleShowAllCampignClick = () => {
        setShowAllCampaign(true);  // Set the state to show the "Approved Campaigns" page
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
        setshowShortlistedInfluencers(false);
    };

    const [showDeliverables, setshowDeliverables] = useState(false);
    const handleDeliverbralesClick = () => {
        setshowDeliverables(true);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
        setShowUserRoles(false);
    }
    const [showConformation, setshowConformation] = useState(false);
    const handleConformationClick = () => {
        setshowConformation(true);
        setShowCreateCampaignForm(false);
        setShowInfluencerApplications(false);
    }
    const location = useLocation();
    const brandName = location.state ? location.state.brandName : null;


    const [selectedBrand, setSelectedBrand] = useState(null);



    const handleBrandClick = (brand) => {
        setSelectedBrand(brand);
    };

    const [isActive, setIsActive] = useState(false);

    const toggleMenu = () => {
        setIsActive(!isActive);
    };


    const handleMouseEnter = () => {
        setIsActive(true);
    };

    const handleMouseLeave = () => {
        setIsActive(false);
    };


    // const [selectedOption, setSelectedOption] = useState('');
  
    return (
        // <div className='imgfull'>
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                {/* Sidebar */}
                <a className="navbar-item" >
                    {/* <span className="icon mr-3">
                        // <img className="ml-5" src="./images/hobo.jpeg" alt="Menu Icon" />
                    </span> */}
                </a>
            </div>
            <div >

                 {/* <div style={{ marginRight: "70pxpx" }} className={`hamburger ${isActive ? 'is-active' : ''}`} onClick={toggleMenu}> */}
                    <span className="icon mr-3">
                        <img src="./images/hobo.jpeg" alt="Menu Icon" style={{ marginRight: "30px" }} />
                    </span> 
                    
                {/* <div className= has-background-info' style={{marginRight:"10rem"}}> */}
               <div className={`navbar-menu ${isActive ? 'is-active' : ''}`} style={{ backgroundColor: 'dark-blue' }}>
                    <aside className={` menu  ${isActive ? '' : 'is-hidden'} overflow-hidden`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <ul className="menu-list d-flex flex-row-reverse" >
                            <div className=' has-text-left ml-5'  >
                                <figure className="image is-32x32" >
                                <img src={logo} alt="Logo" />  
                                </figure>
                                {/* <li className="menu-label " style={{ marginTop: "1rem", marginLeft: "4px" }}>
                                    Brand Dashboard
                                </li> */}
                                <li className='my-5 has-text-white' onClick={handleCreateNewCampaignClick}  style={{ whiteSpace: 'nowrap' }}>
                                    <MenuItem title="Create New Campaign" icon="fas fa-plus" />
                                </li>
                                <li className='my-5 has-text-white' onClick={handleShortlistedInfluencersClick}>
                                    <MenuItem title="Table for influencers" icon="fas fa-plus" />
                                </li>
                                {/* <div className='my-5'>
                                <MenuItem title="Brand Applications" icon="fas fa-users" clickHandler={handleBrandApplicationsClick} />
                            </div> */}
                                <li className='my-5 has-text-white' onClick={handleUserRolesClick}>
                                    <MenuItem title="User Roles " icon="fas fa-users" />
                                </li>
                                {/* <div >
                            <MenuItem title="Settings" icon="fas fa-cog" />
                            </div> */}
                            </div>
                        </ul>
                    </aside>
                    </div>

                </div >



                <ul className="menu1   d-flex flex-row-reverse"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {!isActive && (
                        <div className=' has-text-centered '>
                            <figure className="image is-32x32" >
                                
                            </figure>
                            {!isActive && (
                                <div className='my-5 has-text-white '>
                                    <MenuItem icon="fas fa-plus" clickHandler={handleCreateNewCampaignClick} style={{ marginLeft: "90px", marginTop: "700px" }} />
                                </div>
                            )}
                            {!isActive && (
                                <div className='my-5 has-text-white'>
                                    <MenuItem icon="fas fa-check-circle" clickHandler={handleShortlistedInfluencersClick} style={{ merginButton: "20vw" }} />
                                </div>
                            )}

                            {!isActive && (
                                <div className='has-text-white'>
                                 
                                </div>

                            )}
                        </div>
                    )} 
                     {!isActive && (
                        <li className="ml-5 my-2" style={{ cursor: "pointer", }}>

                            <span className="icon is-small has-text-success">
                                <i className="fas fa-cog" style={{ marginRight: "90px" }} ></i>
                            </span>

                        </li>
                    )}
                </ul>
            {/* </div > */}


            {/* Main content */}
            < div className="column" >
                {/* <h1>Welcome to {brandName} Dashboard</h1> */}
                <div className="columns is-ancestor">
                    <div className="column  mt-5">

                        {showCreateCampaignForm ? (
                            // Render the Create Campaign form
                            <div className="is-child  has-text-centered" style={{ height: '100vh', width: '50vw', marginLeft: '250px' }}>

                                {/* <BrandCampaignForm /> */}
                             
                            </div>
                        ) : showAllCampaign ? (

                            <div className='subtitle' style={{ fontSize: '15px', marginLeft: "90px" }}>

                               

                               
                            </div>



                            //1o651InfluencerApplications
                        ) : showInfluencerApplications ? (
                            <div className='is-child box' style={{ overflowX: 'auto', overflowY: 'auto', width: '1060px' }}>

                                <div className='subtitle' style={{ fontSize: '15px' }}>
                                    <h1 className='underline'>Influencer Applications</h1>
                                    {/* <BrandInfDashboard /> */}
                                   
                                </div>

                            </div>


                        ) : showShortlistedInfluencers ? (
                            <div className='full-page-container' style={{ overflowX: 'auto',marginLeft:"130px", overflowY: 'auto',  }}>

                                <div className='subtitle' style={{ fontSize: '15px' }}>
                                 
                                    <Table/>
                                </div>
                            </div>

                        ) : showDeliverables ? (
                            <div className='is-child box has-text-centered' style={{ overflowX: 'auto', overflowY: 'auto', width: '1060px' }}>
                                <h1 className='subtitle mt-5 underline'>
                                </h1>
                                {/* <InfluencerDeliverblesTable /> */}
                               
                                <div></div>
                            </div>
                        ) : showConformation ? (
                            <div className='is-child box has-text-centered' style={{ overflowX: 'auto', overflowY: 'auto', width: '1060px' }}>
                                <h1 className='subtitle mt-5 underline'>
                                </h1>
                                {/* <InfluencerConformationTable /> */}
                               
                            </div>
                            // showuserRoles
                        ) : showUserRoles ? (
                            <div className='has-text-right ' style={{ marginLeft: "90px", marginBottom: "200px", marginTop: "-35px" }}>
                                <div className='field'>
                                    <div className="control my-3 ">
                                       
                                    </div>
                                </div>
                                {/* <h1 className='subtitle mt-5'>Choose Campaigns
                                </h1> */}




                            </div>
                        ) : showInviteForm ? (
                            // Display the invite form
                            <div className='is-child box has-text-centered'>
                                <h1 className='subtitle mt-5'>Invite form campaign
                                </h1>
                                <div className='form'>

                                    <div className='field '>
                                        <div className="control has-icons-left">
                                            <div className='colomn'>
                                                <input className="input is-rounded "
                                                    type="text"
                                                    placeholder="Email ID"
                                                    value={Email}
                                                    onChange={(e) => setemail(e.target.value)}
                                                />
                                                {Email && !hasValEmail && (
                                                    <small
                                                        className={`ml-1 mt-1  ${!hasValEmail && Email !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                                        {!hasValEmail && Email !== '' ? ('Enter a valid email address') : ''}
                                                    </small>
                                                )}
                                                <span className="icon is-small is-left">
                                                    <i className="fas fa-envelope"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* mobile number */}
                                    <div className='field '>
                                        <div className="control has-icons-left ">
                                            <div className='colomn'>
                                                <input className="input  is-rounded "
                                                    type="text"
                                                    placeholder="Mobile Number"
                                                    value={MobileNumber}
                                                    onChange={(e) => setmobilenumber(e.target.value)}
                                                />

                                                {/* mobile number validation */}
                                                {MobileNumber && !hasValNumber && (
                                                    <div>
                                                        <small
                                                            className={`ml-1 mt-1  ${!hasValNumber && MobileNumber !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                                            {!hasValNumber && MobileNumber !== '' ? 'Please Enter valid Mobile number' : ''}
                                                        </small>
                                                    </div>
                                                )}

                                                <span className='icon is-small is-left'>
                                                    <i className='fas fa-phone'></i>
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="field">
                                        <div className="control">

                                            <button className="button is-primary is-rounded  is-focused is-medium is-fullwidth " disabled={!MobileNumber || !Email || !hasValNumber || !hasValEmail}>
                                                SIGN UP
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        ) : (
                            <div className='subtitle' style={{ fontSize: '15px', marginLeft: "90px" }}>

                              
                            </div>

                        )}

                    </div>
                </div>
            </ div>

        </nav >
        // </div>
    )
}
function MenuItem({ title, icon, clickHandler }) {
    return (
        <li className="ml-2 my-2" style={{ cursor: "pointer" }} onClick={clickHandler}>
            <span className="icon is-small has-text-success mr-1">
                <i className={icon} style={{ marginRight: "50px" }}></i>
            </span>
            <span style={{ marginLeft: "-25px" }}>{title}</span>
        </li>
    );
}

export default Lead