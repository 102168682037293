import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo from "../Logo (1).png";
import { Config } from '../.env.js';

// Utility function to format date as dd-mm-yyyy
const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// Utility function to add days to a date
const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const CampaignForm2 = () => {
  const [products, setProducts] = useState([
    {
      productName: "",
      productLink: "",
      productSlot: "",
      daysToComplete: "",
      distributeSlotsEqually: false,
      date: "",
      dailySlots: [],
      weeklySlots: [],
    },
  ]);
  const [whatsappGroup, setWhatsappGroup] = useState("");

  const location = useLocation();
  const { manager } = location.state || { manager: "Unknown" };
  const navigate = useNavigate();
  const [selectedSlotType, setSelectedSlotType] = useState(""); // Track selected slot type

  useEffect(() => {
    const savedData = localStorage.getItem("formData");
    if (savedData) {
      try {
        const parsedData = JSON.parse(savedData);
        console.log("Loaded data from local storage:", parsedData);
        setProducts(parsedData);
      } catch (error) {
        console.error("Error parsing local storage data:", error);
      }
    }
  }, []);

  useEffect(() => {
    console.log("Saving data to local storage:", products);
    localStorage.setItem("formData", JSON.stringify(products));
  }, [products]);

  const handleInputChange = (index, field, value) => {
    const newProducts = [...products];
    newProducts[index][field] = value;
    if (field === "date" || field === "productSlot") {
      // Recalculate daily and weekly slots when date or productSlot changes
      newProducts[index].dailySlots = generateDailySlots(index);
      newProducts[index].weeklySlots = generateWeeklySlots(index);
      newProducts[index].monthlySlots = generateMonthlySlots(index);
    }
    setProducts(newProducts);
  };

  const handleSlotTypeChange = (e) => {
    setSelectedSlotType(e.target.value);
  };

  const handleDailySlotChange = (productIndex, slotIndex, field, value) => {
    const newProducts = [...products];
    newProducts[productIndex].dailySlots[slotIndex][field] = value;
    setProducts(newProducts);
  };

  const handleWeeklySlotChange = (productIndex, slotIndex, field, value) => {
    const newProducts = [...products];
    newProducts[productIndex].weeklySlots[slotIndex][field] = value;
    setProducts(newProducts);
  };
  const handleMonthlySlotChange = (productIndex, slotIndex, field, value) => {
    const newProducts = [...products];
    newProducts[productIndex].monthlySlots[slotIndex][field] = value;
    setProducts(newProducts);
  };
  const generateDailySlots = (index) => {
    const product = products[index];
    const slots = [];
    if (product.date && product.productSlot) {
      // const startDate = new Date(product.date.split('-').reverse().join('-'));
      const startDate = product.date;
      const totalSlots = Number(product.productSlot);
      const  daysToComplete = Number(product.daysToComplete)
      for (let i = 0; i < daysToComplete; i++) {
        const currentDate = addDays(startDate, i);
        slots.push({ date: formatDate(currentDate), slots: "" });
      }
    }
    return slots;
  };

  const generateWeeklySlots = (index) => {
    const product = products[index];
    const slots = [];
    if (product.date && product.productSlot) {
      // const startDate = new Date(product.date.split('-').reverse().join('-'));
      const startDate = product.date;
      const totalDays = Number(product.daysToComplete);
      const totalSlots = Number(product.productSlot);
      const weeks = Math.ceil(totalDays / 7); // Total weeks including partial weeks

      // if totalslots%weeks == 0
      let slotsPerWeek = (totalSlots / weeks).toFixed(2);

      // else
      // slotsinFirstWeek = Math.ceil(totalslots/weeeks)
      // slotsinsecondweek = Math.ceil(totalslots-slotsinfirstweek/weeks-1)

      for (let i = 0; i < weeks; i++) {
        const weekStartDate = addDays(startDate, i * 7);
        let weekEndDate = addDays(weekStartDate, 6);
        if (i === weeks - 1) {
          // Last week, might not be a full week
          weekEndDate = addDays(startDate, totalDays - 1);
        }

        slots.push({
          startDate: formatDate(weekStartDate),
          endDate: formatDate(weekEndDate),
          dis_slots: slotsPerWeek,
        });
      }
    }
    return slots;
  };

  const generateMonthlySlots = (index) => {
    const product = products[index];
    const slots = [];
    if (product.date && product.productSlot) {
      // const startDate = new Date(product.date.split('-').reverse().join('-'));
      const startDate = product.date;
      const totalDays = Number(product.daysToComplete);
      const totalSlots = Number(product.productSlot);
      const month = Math.ceil(totalDays / 30); // Total weeks including partial weeks

      let remainingSlots = totalSlots;

      for (let i = 0; i < month; i++) {
        const monthStartDate = addDays(startDate, i * 30);
        let monthEndDate = addDays(monthStartDate, 29);
        if (i === month - 1) {
          // Handle the last slot, which might not cover a full week
          monthEndDate = addDays(startDate, totalDays - 1);
        }

        // const slotsForMonth = Math.min(totalSlots, remainingSlots / month);
        const slotsForMonth  = totalSlots/month.toFixed(2)
        slots.push({
          startDate: formatDate(monthStartDate),
          endDate: formatDate(monthEndDate),
          dis_slots: slotsForMonth.toFixed(2),
        });

        remainingSlots -= slotsForMonth;
      }
    }
    return slots;
  };

  const addBrandField = () => {
    setProducts([
      ...products,
      {
        productName: "",
        productLink: "",
        productSlot: "",
        daysToComplete: "",
        distributeSlotsEqually: false,
        date: "",
        dailySlots: [],
        weeklySlots: [],
      },
    ]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedProducts = products.map((product) => {
      if (
        product.distributeSlotsEqually &&
        product.productSlot &&
        product.daysToComplete
      ) {
        const slotsPerDay = product.productSlot / product.daysToComplete;
        return { ...product, slotsPerDay: slotsPerDay.toFixed(2) };
      }
      return product;
    });

    try {
      const response = await axios.post(`${Config.API_URL}/api/product`, {
        products: updatedProducts,
      });
      console.log("Form data submitted", response.data);
      navigate("/conformation", { state: { manager } });
    } catch (error) {
      console.error("Error submitting form data", error);
    }
  };

  return (
    <div className="is-flex is-justify-content-center ">
      <div className="box" style={{ width: "820px", padding: "24px 10px" }}>
        <div
          className="is-flex is-justify-content-center is-flex-direction-column is-align-items-center mt-5"
          style={{ marginBottom: "67px" }}
        >
          <figure className="image is-96x96 ">
            <img src={logo} alt="logo" />
          </figure>
          <h1
            className="has-text-weight-bold mt-5 is-size-2"
            style={{ color: "#2E2F2E", marginTop: "45px" }}
          >
            Start Your Campaign
          </h1>
        </div>
        <form onSubmit={handleSubmit}>
          {products.map((product, index) => (
            <div key={index}>
              <h2 style={{ marginTop: "20px", marginBottom: "20px" }}>
                Details of Your Item {index + 1}
              </h2>
              <div className="field is-small">
                <label className="label has-text-left">Item Name*</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="Product Name"
                    value={product.productName}
                    onChange={(e) =>
                      handleInputChange(index, "productName", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-left">Item Link*</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="url"
                    placeholder="Product Link"
                    value={product.productLink}
                    onChange={(e) =>
                      handleInputChange(index, "productLink", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-left">
                  Days to Complete the Slot*
                </label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="number"
                    placeholder="Days to Complete the Slot"
                    value={product.daysToComplete}
                    onChange={(e) =>
                      handleInputChange(index, "daysToComplete", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-left">Total Item Slot*</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="number"
                    placeholder="Product Slot"
                    value={product.productSlot}
                    onChange={(e) =>
                      handleInputChange(index, "productSlot", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-left">
                  Campaign Live Date*
                </label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="date"
                    placeholder="dd-mm-yyyy"
                    value={product.date}
                    onChange={(e) =>
                      handleInputChange(index, "date", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={product.distributeSlotsEqually || false}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "distributeSlotsEqually",
                          e.target.checked
                        )
                      }
                    />
                    Distribute Slots Equally*
                  </label>
                </div>
              </div>
              {selectedSlotType === "daily" &&
                product.distributeSlotsEqually && (
                  <div className="field">
                    <label className="label has-text-left">Slots Per Day</label>
                    <div className="control">
                      <input
                        className="input is-rounded"
                        type="text"
                        value={(
                          (product.productSlot || 0) /
                          (product.daysToComplete || 1)
                        ).toFixed(2)}
                        readOnly
                      />
                    </div>
                  </div>
                )}

              {selectedSlotType === "daily" &&
                !product.distributeSlotsEqually && (
                  <div className="field">
                    <label className="label has-text-left">Daily Slots</label>
                    <div className="control">
                      {generateDailySlots(index).map((slot, slotIndex) => (
                        <div key={slotIndex} className="field">
                          <input
                            className="input is-rounded"
                            type="text"
                            value={slot.date}
                          />
                          <input
                            className="control is-rounded"
                            type="number"
                            placeholder="Slots"
                            value={product.dailySlots[slotIndex]?.slots || ""}
                            onChange={(e) =>
                              handleDailySlotChange(
                                index,
                                slotIndex,
                                "slots",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {selectedSlotType === "weekly" &&
                !product.distributeSlotsEqually && (
                  <div className="field">
                    <label className="label has-text-left">Weekly Slots</label>
                    <div className="control">
                      {generateWeeklySlots(index).map((slot, slotIndex) => (
                        <div key={slotIndex} className="field">
                          <input
                            className="input is-rounded"
                            type="text"
                            value={`${slot.startDate} to ${slot.endDate}`}
                          />
                          <input
                            className="control is-rounded "
                            type="number"
                            placeholder="Slots"
                            value={product.weeklySlots[slotIndex]?.slots || ""}
                            onChange={(e) =>
                              handleWeeklySlotChange(
                                index,
                                slotIndex,
                                "slots",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {selectedSlotType === "weekly" &&
                product.distributeSlotsEqually && (
                  <div className="field">
                    <label className="label has-text-left">Weekly Slots</label>
                    <div className="control">
                      {generateWeeklySlots(index).map((slot, slotIndex) => (
                        <div key={slotIndex} className="field">
                          <input
                            className="input is-rounded"
                            type="text"
                            value={`${slot.startDate} to ${slot.endDate}`}
                          />
                          <input
                            className="control is-rounded "
                            type="number"
                            placeholder="Slots"
                            value={slot.dis_slots}
                            readOnly
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              {selectedSlotType === "monthly" &&
                !product.distributeSlotsEqually && (
                  <div className="field">
                    <label className="label has-text-left">Monthly</label>
                    <div className="control">
                      {generateMonthlySlots(index).map((slot, slotIndex) => (
                        <div key={slotIndex} className="field">
                          <input
                            className="input is-rounded"
                            type="text"
                            value={`${slot.startDate} to ${slot.endDate}`}
                          />
                          <input
                            className="control is-rounded "
                            type="number"
                            placeholder="Slots"
                            value={product.monthlySlots[index]?.slots || ""}
                            onChange={(e) =>
                              handleMonthlySlotChange(
                                index,
                                slotIndex,
                                "slots",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              {selectedSlotType === "monthly" &&
                product.distributeSlotsEqually && (
                  <div className="field">
                    <label className="label has-text-left">Monthly</label>
                    <div className="control">
                      {generateMonthlySlots(index).map((slot, slotIndex) => (
                        <div key={slotIndex} className="field">
                          <input
                            className="input is-rounded"
                            type="text"
                            value={`${slot.startDate} to ${slot.endDate}`}
                          />
                          <input
                            className="control is-rounded "
                            type="number"
                            placeholder="Slots"
                            value={slot.dis_slots}
                            readOnly
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              <div className="field">
                <div className="control">
                  <div className="colomn">
                    <div className="my-2 has-text-left">
                      <strong>Define Slots*</strong>
                    </div>
                    <div
                      className="checkboxContainer"
                      style={{ display: "inline-flex", flexDirection: "row" }}
                    >
                      <input
                        type="radio"
                        value="monthly"
                        checked={selectedSlotType === "monthly"}
                        onChange={handleSlotTypeChange}
                        className="checkbox"
                      />
                      <label className="checkboxLabel">Monthly</label>

                      <input
                        type="radio"
                        value="weekly"
                        checked={selectedSlotType === "weekly"}
                        onChange={handleSlotTypeChange}
                        className="checkbox"
                      />
                      <label className="checkboxLabel">Weekly</label>

                      <input
                        type="radio"
                        value="daily"
                        checked={selectedSlotType === "daily"}
                        onChange={handleSlotTypeChange}
                        className="checkbox"
                      />
                      <label className="checkboxLabel">Daily</label>
                    </div>
                  </div>
                </div>
              </div>
              <label className="radio">
                <input
                  type="radio"
                  name="whatsappGroup"
                  value="yes"
                  onChange={(e) => setWhatsappGroup(e.target.value)}
                />
                Move Incomplete Slots to Next Day/Week/Month
              </label>
            </div>
          ))}
          <div className="field">
            <button
              type="button"
              className="button is-primary mt-3 is-rounded"
              onClick={addBrandField}
            >
              Add More Items
            </button>
          </div>
          {/* <div className="field">
                        <button
                            type="button"
                            className="button is-danger"
                            onClick={() => handleRemoveFields(index)}
                        >
                            Delete Items
                        </button>
                    </div> */}
          <div className="field">
            <div className="control is-flex is-justify-content-center">
              <button
                className="button is-link  mt-3"
                type="submit"
                style={{ width: "30%" }}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CampaignForm2;