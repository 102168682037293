import React, { useState } from 'react';
import InviteForm from '../pages/userRole'; // Import the InviteForm component


const Modal = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            <button className="button is-primary" onClick={openModal}>Open Invite Form</button>
            {isOpen &&
                <div className="modal is-active">
                    <div className="modal-background" onClick={closeModal}></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title has-text-centered mt-5">Invite Form</p>
                            <button className="delete" aria-label="close" onClick={closeModal}></button>
                        </header>
                        <section className="modal-card-body">
                            <InviteForm onClose={closeModal} /> {/* Pass onClose prop to the InviteForm */}
                        </section>
                     
                    </div>
                </div>
            }
        </>
    );
};
export default Modal;
