import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import 'bulma/css/bulma.min.css';


library.add(fas);

const Payment = () => {
    const location = useLocation();
    const brandName = location.state ? location.state.brandName : null;
    const [menuVisible, setMenuVisible] = useState(false);

    const handleToggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    // Other state variables and functions...

    return (
        <div className="columns is-gapless">
            <div 
                className={`column is-narrow ${menuVisible ? 'is-active' : ''}`} 
                onMouseEnter={() => setMenuVisible(true)} 
                onMouseLeave={() => setMenuVisible(false)}
            >
                <aside className="menu">
                    {/* <ul className="menu-list">
                        <li className='my-5 has-text-white' onClick={handleCreateNewCampaignClick}>
                            <MenuItem title="Create New Campaign" icon="fas fa-plus" />
                        </li>
                        <li className='my-5 has-text-white' onClick={handleShowAllCampignClick}>
                            <MenuItem title="All Campaigns" icon="fas fa-check-circle" />
                        </li>
                        <li className='my-5 has-text-white' onClick={handleUserRolesClick}>
                            <MenuItem title="User Roles " icon="fas fa-users" />
                        </li>
                    </ul> */}
                </aside>
            </div>
            <div className="column">
                <nav className="navbar" role="navigation" aria-label="main navigation">
                    <div className="navbar-brand">
                        <a 
                            role="button" 
                            className={`navbar-burger ${menuVisible ? 'is-active' : ''}`} 
                            aria-label="menu" 
                            aria-expanded="false" 
                            onClick={handleToggleMenu}
                        >
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>
                </nav>
                <section className="section">
                    <div className="container">
                        <h1 className="title">Dashboard Content</h1>
                        <p className="subtitle">Main content goes here.</p>
                    </div>
                </section>
            </div>
        </div>
    );
};

const MenuItem = ({ title, icon, clickHandler }) => {
    return (
        <li className="ml-2 my-2" style={{ cursor: "pointer" }} onClick={clickHandler}>
            <span className="icon is-small has-text-success mr-1">
                <i className={icon} style={{ marginRight: "50px" }}></i>
            </span>
            <span style={{ marginLeft: "-25px" }}>{title}</span>
        </li>
    );
};

export default Payment;
