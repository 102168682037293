import { Config } from '../.env.js';
export const signup = async ({yourName,email,phone,password,otp,}) => {
    const user = {yourName,email,phone,password,otp,};

    try {
        const res = await fetch(`${Config.API_URL}/signup`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(user)
        });
        return await res.json();
    } catch (err) {
        throw new Error(`cannot register at this time . ${err}`);
    }
};


export const getAllCampaigns = async () => {
    try {
        const response = await fetch(`${Config.API_URL}/api/getcampaign`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching campaigns:', error);
        throw error;
    }
};
export const login = async ({ email, password,  } = {}) => {
    const user = { email, password, };

    try {
        const res = await fetch(`${Config.API_URL}/login`, {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(user)
        });
        return await res.json();
    } catch (err) {
        throw new Error(`Cannot login at this time. ${err}`);
    }
};
