import React, { useState } from 'react'
import './App.css';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import AdminDashboard from './pages/AdminDashboard';
import Admin from './pages/AdminDashboard';
import LeadDashboard from './pages/LeadDashboard'; 
import CampaignMangerDashboard from './pages/CampaignMangerDashborad';
import PaymentApproveDashboard from './pages/PaymentApproveDashboard';
import CampaignForm from './pages/CampaignCreateForm';
import ConformationForm from './pages/ConformationForm';
import Table from './pages/masterTable';
import CampaignForm2 from './pages/ProductEntity';
import  OrderForm from './pages/orderForm'
import Signup from './Auth/signUp';
import OTP from './pages/Otp'
import Refund from './pages/refundForm'
 import AllCamp from './pages/allCampaign';
import Live from './pages/live';
import OrderTable from './pages/orderTable';
import ForgetPassword from './Auth/forgotPassword';
import SignIn from './Auth/signIn';
import Home from './pages/Home';
import OtpPage from './pages/otpPage';

 
import UserManagement from './pages/userRole';
import Modal from './pages/invite';
import UserTable from './pages/userTable';
import InviteForm1 from './pages/EditInvite';
import DataDisplay from './pages/dataDisplay'
import RefundTemp from './pages/tempRefund';

const App = () => {
  const [showHeader, setShowHeader] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  return (
    <div className='hero'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path = '/admin' element ={<AdminDashboard/>}/>
          <Route path = '/lead' element ={<LeadDashboard/>}/>
          <Route path = '/campaignManger' element ={<CampaignMangerDashboard/>}/>
          <Route path = '/approver' element ={<PaymentApproveDashboard/>}/>
          <Route path  = '/conformation' element = {<ConformationForm/>}/>
          <Route path = '/table' element = {<Table/>}/>
          <Route path = '/campaign2' element = {<CampaignForm2/>}/>
          <Route path = '/live' element = {<OrderForm/>}/>
          <Route path="/signup" element={<Signup />} />
          <Route path="/otp/:Email" element={<OTP />} />
          <Route path = "/live-campaign" element = {<Live/>}/>
          <Route path = "/data" element = {<DataDisplay/>}/>
          <Route path = "/forgot-password" element = {<ForgetPassword/>}/>
          <Route path = "/signin" element = {<SignIn/>}/>
          <Route path = "/resendotp" element = {<OrderTable/>}/>
          <Route path="/otp1/:email" element={<OtpPage />} />
          <Route path = "/userRole" element = {<UserManagement/>}/>
          <Route path = "/modal" element = {<Modal/>}/>
          <Route path = "/user-table" element = {<UserTable/>}/>
          <Route path = "/editInvite" element = {<InviteForm1/>}/>
          <Route path="/" element={<AllCamp />} />
          <Route path="/order-form" element={<OrderForm />} />
          <Route path="/refund-form:campaignName" element={<Refund />} />
          <Route path="/order-table" element={<OrderTable />} />
          <Route path="/template-refund" element={<RefundTemp />} />
        
        </Routes>
        
      </BrowserRouter>

    </div>
  )
}

export default App




