import React, { useState } from 'react';
import axios from 'axios';
import { Config } from '../.env.js';

const Form2 = () => {
    const [fullName, setFullName] = useState('');
    const [orderId, setOrderId] = useState('');
    const [upiId, setUpiId] = useState('');
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [beneficiaryName, setBeneficiaryName] = useState('');
    const [bankName, setBankName] = useState('');
    const [products, setProducts] = useState([{
        amazonReviewName: '',
        productTask: '',
        orderDeliveredSS: null,
        amazonVerifiedReviewLink: '',
        amazonVerifiedReviewSS: null,
        starRatingValue: '',
        sellerFeedbackSS: null,
        invoiceReceivedSS: null,
        invoiceNumber: ''
    }]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('fullName', fullName);
        formData.append('orderId', orderId);
        formData.append('upiId', upiId);
        formData.append('bankAccountNumber', bankAccountNumber);
        formData.append('ifscCode', ifscCode);
        formData.append('beneficiaryName', beneficiaryName);
        formData.append('bankName', bankName);

        products.forEach((product, index) => {
            formData.append(`products[${index}].amazonReviewName`, product.amazonReviewName);
            formData.append(`products[${index}].productTask`, product.productTask);
            if (product.orderDeliveredSS) formData.append(`products[${index}].orderDeliveredSS`, product.orderDeliveredSS);
            formData.append(`products[${index}].amazonVerifiedReviewLink`, product.amazonVerifiedReviewLink);
            if (product.amazonVerifiedReviewSS) formData.append(`products[${index}].amazonVerifiedReviewSS`, product.amazonVerifiedReviewSS);
            formData.append(`products[${index}].starRatingValue`, product.starRatingValue);
            if (product.sellerFeedbackSS) formData.append(`products[${index}].sellerFeedbackSS`, product.sellerFeedbackSS);
            if (product.invoiceReceivedSS) formData.append(`products[${index}].invoiceReceivedSS`, product.invoiceReceivedSS);
            formData.append(`products[${index}].invoiceNumber`, product.invoiceNumber);
        });

        try {
            const response = await axios.post(`${Config.API_URL}/api/formdata`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Form data submitted:', response.data);
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    };

    const handleInputChange = (index, event) => {
        const { name, value, files } = event.target;
        const updatedProducts = [...products];
        if (files.length > 0) {
            updatedProducts[index][name] = files[0];
        } else {
            updatedProducts[index][name] = value;
        }
        setProducts(updatedProducts);
    };

    const addProduct = () => {
        setProducts([...products, {
            amazonReviewName: '',
            productTask: '',
            orderDeliveredSS: null,
            amazonVerifiedReviewLink: '',
            amazonVerifiedReviewSS: null,
            starRatingValue: '',
            sellerFeedbackSS: null,
            invoiceReceivedSS: null,
            invoiceNumber: ''
        }]);
    };

    const removeProduct = (index) => {
        const updatedProducts = products.filter((_, i) => i !== index);
        setProducts(updatedProducts);
    };

    return (
        <div>
            <h1 className='title has-text-centered mt-3'>
                Refund Form
            </h1>
            <form onSubmit={handleSubmit}>
                {products.map((product, index) => (
                    <div key={index} className="box">
                        <div className="field">
                            <label className="label">Amazon Review Account Holder Name</label>
                            <div className="control">
                                <input
                                    className="input is-rounded"
                                    type="text"
                                    name="amazonReviewName"
                                    placeholder="Enter Amazon Review Account Holder Name"
                                    value={product.amazonReviewName}
                                    onChange={event => handleInputChange(index, event)}
                                />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Choose Ordered and Reviewed Product/Task</label>
                            <div className="control">
                                <input
                                    className="input is-rounded"
                                    type="text"
                                    name="productTask"
                                    placeholder="Enter Product or Task Description"
                                    value={product.productTask}
                                    onChange={event => handleInputChange(index, event)}
                                />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Order Delivered Screenshot (Optional)</label>
                            <div className="control">
                                <input
                                    className="input is-rounded"
                                    type="file"
                                    name="orderDeliveredSS"
                                    onChange={event => handleInputChange(index, event)}
                                />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Amazon Verified Review Link/Deliverable Live Link</label>
                            <div className="control">
                                <input
                                    className="input is-rounded"
                                    type="text"
                                    name="amazonVerifiedReviewLink"
                                    placeholder="Enter Verified Review Link or Deliverable Live Link"
                                    value={product.amazonVerifiedReviewLink}
                                    onChange={event => handleInputChange(index, event)}
                                />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Amazon Verified Review Screenshot/Deliverable Live Screenshot/Submitted Rating Screenshot</label>
                            <div className="control">
                                <input
                                    className="input is-rounded"
                                    type="file"
                                    name="amazonVerifiedReviewSS"
                                    onChange={event => handleInputChange(index, event)}
                                />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Value of Star Rating Given (Optional)</label>
                            <div className="control">
                                <input
                                    className="input is-rounded"
                                    type="text"
                                    name="starRatingValue"
                                    placeholder="Enter Star Rating Value"
                                    value={product.starRatingValue}
                                    onChange={event => handleInputChange(index, event)}
                                />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Seller Feedback Screenshot (Optional)</label>
                            <div className="control">
                                <input
                                    className="input is-rounded"
                                    type="file"
                                    name="sellerFeedbackSS"
                                    onChange={event => handleInputChange(index, event)}
                                />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Invoice Received Screenshot (Optional)</label>
                            <div className="control">
                                <input
                                    className="input is-rounded"
                                    type="file"
                                    name="invoiceReceivedSS"
                                    onChange={event => handleInputChange(index, event)}
                                />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Invoice Number (Optional)</label>
                            <div className="control">
                                <input
                                    className="input is-rounded"
                                    type="text"
                                    name="invoiceNumber"
                                    placeholder="Enter Invoice Number"
                                    value={product.invoiceNumber}
                                    onChange={event => handleInputChange(index, event)}
                                />
                            </div>
                        </div>

                        {/* <button type="button" onClick={() => removeProduct(index)} className="button is-danger is-rounded">
                            Remove Product
                        </button> */}
                    </div>
                ))}

                {/* <div className="field">
                    <div className="control">
                        <button type="button" onClick={addProduct} className="button is-info is-rounded">
                            Add Another Product
                        </button>
                    </div>
                </div> */}

                <div className="field">
                    <label className="label">Order ID</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            placeholder="Enter Order ID"
                            value={orderId}
                            onChange={(e) => setOrderId(e.target.value)}
                        />
                    </div>
                </div>

                <div className="field">
                    <label className="label">UPI ID</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            placeholder="Enter UPI ID"
                            value={upiId}
                            onChange={(e) => setUpiId(e.target.value)}
                        />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Bank Account Number</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            placeholder="Enter Bank Account Number"
                            value={bankAccountNumber}
                            onChange={(e) => setBankAccountNumber(e.target.value)}
                        />
                    </div>
                </div>

                <div className="field">
                    <label className="label">IFSC Code</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            placeholder="Enter IFSC Code"
                            value={ifscCode}
                            onChange={(e) => setIfscCode(e.target.value)}
                        />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Beneficiary Name</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            placeholder="Enter Beneficiary Name"
                            value={beneficiaryName}
                            onChange={(e) => setBeneficiaryName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Bank Name</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            placeholder="Enter Bank Name"
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="field">
                    <div className="control">
                        <button className="button is-primary" type="submit">
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Form2;
